import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginStaff from './login-staff';
import LoginCustomer from './login-customer';
import LoginType from './login-type';

const LoginRouter = props => {
    return (
        <div className="form-login">
            <div className="app-container-login">
                <div className="container">
                    <div className="row justify-content-center h-100">
                        <div className="col-xl-6 col-sm-12 m-auto white-bg p1-border px-5 py-3 rounded">
                            <Routes>
                                <Route
                                    path="/Login/Customer"
                                    element={<LoginCustomer checkAuth={props.checkAuth} />}
                                />
                                <Route
                                    exact
                                    path="/Login/Staff"
                                    element={<LoginStaff checkAuth={props.checkAuth} />}
                                />
                                <Route
                                    exact
                                    path="/Login"
                                    element={<LoginType checkAuth={props.checkAuth} />}
                                />
                                <Route
                                    path="/*"
                                    element={<LoginType checkAuth={props.checkAuth} />}
                                />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginRouter;
