import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateEmployee } from '../../../api/employee-api';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const EmployeeCreate = (props) => {
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [employee, setEmployee] = useState(
        {
            userID: -1,
            customerID: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            changePassword: true,
            role: 1,
            active: true
        }
    );
    const [roles, setRoles] = useState([
        { name: "Admin", value: 0 },
        { name: "Agent", value: 1 }
    ]);


    useEffect(() => {
        
    }, []);

    const activeChange = (e) => {
        let newState = { ...employee };
        newState.active = e.target.checked;
        setEmployee(newState);
    }
    const updateEmployeeState = (e) => {
        let newState = { ...employee };
        newState[e.target.name] = e.target.value;
        setEmployee(newState);
    }

    const createEmployee = () => {
        let payload = employee;
        payload.role = Number(payload.role);
        CreateEmployee(payload)
            .then((result) => {
                if (result.success) {
                    navigate(`/Admin/Employees`)
                }
                else {
                    setMessage("");
                    setErrorMessage(result.message);
                }
            })
            .catch((result) => {
                setMessage("");
                setErrorMessage("Unknown error occurred.");
            });
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Create New Employee</h1>
                </div>
            </div>
            <Form className='dt-rem-3' onSubmit={(e) => { e.preventDefault(); createEmployee() }}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First name" name="firstName" value={employee.firstName} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" value={employee.lastName} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email address" name="email" value={employee.email} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="Phone number" name="phone" value={employee.phone} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Role</Form.Label>
                        <Form.Select name="role" value={employee.role} onChange={(e) => updateEmployeeState(e)} >
                            {roles.map((r) =>
                                <option key={r.value} value={r.value} >{r.name}</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                </Row>
                {message.length > 0 ?
                    <Alert variant="success">
                        {message}
                    </Alert>
                    : null
                }
                {errorMessage.length > 0 ?
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                    : null
                }
                <div className='pt-2 row'>
                    <div className='col-lg'>
                        <Button className='deaftalk-button' type="submit">
                            Add Employee
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default EmployeeCreate;
