import APIRequest from './api';

export const GetNotes = (date) => {
    return APIRequest({
        url: `Note/GetNotes`,
        method: "post",
        body: {
            date:date
        }
    });
}

export const AddNote = (text) => {
    return APIRequest({
        url: `Note/AddNote`,
        method: "post",
        body: {
            text: text
        }
    });
}
