import APIRequest from './api';

export const GetEmployee = (userID) => {
    return APIRequest({
        url: `Employee/GetEmployee`,
        method: "post",
        body: {
            userID: userID,
        }
    });
}
export const GetEmployees = (active) => {
    return APIRequest({
        url: `Employee/GetEmployees`,
        method: "post",
        body: {
            active: active
        }
    });
}
export const CreateEmployee = (employee) => {
    return APIRequest({
        url: `Employee/CreateEmployee`,
        method: "post",
        body: {
            employee: employee
        }
    });
}
export const UpdateEmployee = (employee) => {
    return APIRequest({
        url: `Employee/UpdateEmployee`,
        method: "post",
        body: {
            employee: employee
        }
    });
}
export const ResetEmployee = (userID) => {
    return APIRequest({
        url: `Employee/ResetEmployee`,
        method: "post",
        body: {
            userID: userID
        }
    });
}
