import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap'

import { GetNavigation } from '../../api/navigation-api';

const Navigation = (props) => {

    const [navigation, setNavigation] = useState([]);
    useEffect(() => {
        loadNavigation();
    },[]);

    const loadNavigation = () => {
        GetNavigation()
            .then((result) => {
                setNavigation(result.navigationItems);
            })
            .catch(() => {
                
            });
    };

    return (
        <Navbar expand="lg" bg="light" className="pt-0 pb-0">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {navigation.map((item, index) => {
                            
                                return item.children && item.children.length > 0 ?
                                    <NavDropdown key={item.destination} title="Admin">
                                        {item.children.map((cItem) => {
                                            return <LinkContainer key={cItem.destination} to={cItem.destination} key={cItem.destination}>
                                                <NavDropdown.Item className={"nav-link"}>{cItem.name}</NavDropdown.Item>
                                            </LinkContainer>
                                        })}
                                    </NavDropdown>
                                    :
                                    <LinkContainer key={item.destination} to={item.destination} key={item.destination}>
                                        <Nav.Link>{item.name}</Nav.Link>
                                    </LinkContainer>
                        })}
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={() => props.logout()}>
                            Logout
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;