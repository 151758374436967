import APIRequest from './api';

export const Authenticate = (email, password) => {
    return APIRequest({
        url: `Authentication/Authenticate`,
        method: "post",
        body: {
            email: email,
            password: password
        }
    });
}
export const AuthenticateCustomer = (email, password) => {
    return APIRequest({
        url: `Authentication/AuthenticateCustomer`,
        method: "post",
        body: {
            email: email,
            password: password
        }
    });
}
export const UpdatePassword = (password) => {
    return APIRequest({
        url: `Authentication/UpdatePassword`,
        method: "post",
        body: {
            password: password
        }
    });
}
export const CheckAuth = () => {
    return APIRequest({
        url: `Authentication/CheckAuth`,
        method: "get",
    });
}
export const Logout = () => {
    return APIRequest({
        url: `Authentication/Logout`,
        method: "get",
    });
}

