import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CheckAuth, Logout } from './api/auth-api';

import LoginRouter from './components/login/login-router';
import SetPassword from './components/login/set-password';
import MainRouter from './components/main-router'

import './css/style.css';
import '@fortawesome/fontawesome-free/css/all.css';

const App = () => {
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState("");
    const [role, setRole] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const [changePassword, setChangePassword] = useState(false);

    let navigate = useNavigate();
    let location = useLocation()

    useEffect(() => {
        checkAuth();
    }, [location]);

    useEffect(() => {
        checkAuth();
        if (authenticated && (window.location.pathname === "" || window.location.pathname === "/")) {
            navigate(`/Dashboard`)
        }
    }, [authenticated]);

    const checkAuth = () => {
        setLoading(true);
        CheckAuth()
            .then((result) => {
                if (result.success === false) {
                    localStorage.setItem('deaftalk-token', null);
                    sessionStorage.setItem('deaftalk-token', null);
                    setAuthenticated(false);
                    setLoading(false);
                    if (result.redirected) {
                        navigate(`/`)
                    }
                }
                else {
                    setAuthenticated(true);
                    setLoading(false);
                    setUserType(result.userType);
                    setRole(result.role);
                    setChangePassword(result.changePassword);
                }
            })
            .catch((result) => {
                localStorage.setItem('deaftalk-token', null);
                sessionStorage.setItem('deaftalk-token', null);
                setAuthenticated(false);
                setLoading(false);
                if (result.redirected) {
                    navigate(`/`)
                }
            });
    };
    const needsChangePassword = (val) => {
        setChangePassword(val);
    }

    const logout = () => {
        setLoading(true);
        Logout()
            .then((result) => {
                localStorage.setItem('deaftalk-token', null);
                sessionStorage.setItem('deaftalk-token', null);
                setAuthenticated(false);
                setLoading(false);
                navigate(`/`)
            })
            .catch(() => {
                navigate(`/`)
            });
    }

    return (
        <div className={(authenticated ? "app-main-container" : "app-container") +  " min-vh-100"}>
            {loading ? null : authenticated ?
                changePassword ?
                    <SetPassword needsChangePassword={needsChangePassword} /> :
                    (
                        <MainRouter role={role} userType={userType} logout={logout} checkAuth={checkAuth} />
                    ) : (
                    <LoginRouter checkAuth={checkAuth}></LoginRouter>
                )
            }
        </div>
    );
};

export default App;