import React, { useState, useEffect, } from 'react';
import NoteBoard from '../notes/note-board'
import { Link } from "react-router-dom";

const Dashboard = (props) => {

    useEffect(() => {
        document.title = "DT Interpreting Portal"
    }, []);

    return (
        <div className='container'>
            {props.userType === "employee" ?
                <NoteBoard />
                :
                props.userType === "customer" ?
                    <div className='container'>
                        <div className='pt-2 row'>
                            <div className='col-lg'>
                                <h1>Welcome!</h1>
                            </div>
                        </div>
                        <div className='pt-2 row dt-rem-3'>
                            <div className='col-lg'>
                                Please see the <Link to='/Reports'>reporting</Link> page to pull your invoices. 
                            </div>
                        </div>
                        <div className='pt-5 row dt-rem-3'>
                            <div className='col-lg'>
                                If you need assistance, please contact us <a href='http://www.dtinterpreting.com/contact'>here.</a>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    );
};
export default Dashboard;
