import APIRequest from './api';


export const UploadFilePond = (params) => {
    let deaftalkToken = localStorage.getItem("deaftalk-token");
    if (deaftalkToken === null)
        deaftalkToken = sessionStorage.getItem("deaftalk-token");

    const headers = {};
    if (deaftalkToken !== null)
        headers['Authorization'] = `Bearer ${deaftalkToken}`
    return {
        url: `/api/${params.controller}`,
        process: {
            url: `/${params.action}`,
            method: "POST",
            headers: headers,
            ondata: (formData) => {
                for (var x = 0; x < params.formData.length; x++) {
                    formData.append(params.formData[x].key, params.formData[x].value);
                }
                return formData;
            },
            onload: (response) => {
                params.onload(response)
            }
        },
        load: null,
        revert: null,
        restore: null,
        fetch: null,
        patch: null
    }
}
export const ImportStagedCallData = (calls) => {
    return APIRequest({
        url: `Import/StagedCallData`,
        method: "post",
        body: {
            calls: calls,
        }
    });
}
