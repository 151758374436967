import APIRequest from './api';

export const GetCustomer = (customerID) => {
    return APIRequest({
        url: `Customer/GetCustomer`,
        method: "post",
        body: {
            customerID: customerID
        }
    });
}
export const GetCustomers = (active) => {
    return APIRequest({
        url: `Customer/GetCustomers`,
        method: "post",
        body: {
            active: active
        }
    });
}
export const CreateCustomer = (customer, user, rates) => {
    return APIRequest({
        url: `Customer/CreateCustomer`,
        method: "post",
        body: {
            customer: customer,
            user: user,
            rates: rates
        }
    });
}
export const UpdateCustomer = (customer, user, rates) => {
    return APIRequest({
        url: `Customer/UpdateCustomer`,
        method: "post",
        body: {
            customer: customer,
            user: user,
            rates: rates
        }
    });
}
export const ResetCustomer = (customerID) => {
    return APIRequest({
        url: `Customer/ResetCustomer`,
        method: "post",
        body: {
            customerID: customerID
        }
    });
}

