import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const LoginType = props => {

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "DT Interpreting - Login"
    }, []);

    const customer = () => {
        navigate(`/Login/Customer`);
    }
    const staff = () => {
        navigate(`/Login/Staff`);
    }
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-10 row justify-content-center">
                    <img className="img-fluid" src="../images/dt-logo-sq.png" alt="Deaftalk Portal" style={{ "width": "340px", "height": "340px" }} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col text-center">
                    <Button className='deaftalk-button deaftalk-login-type-button-main d-inline-flex align-items-center' onClick={() => customer()}>
                        <FontAwesomeIcon size="2x" icon={faUser} />
                        <span>Continue as Customer</span>
                    </Button>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col text-center">
                    <Button className='deaftalk-button deaftalk-login-type-button-secondary d-inline-flex align-items-center' onClick={() => staff()}>
                        <span>Or Continue as Staff</span>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default LoginType;
