import React from 'react';
import PropTypes from 'prop-types';
import '../css/loader.css';


function LoadingShroud(props) {
    return (
        <>
            {
                props.Active &&
                <div className="cs-loader">
                    <div className="cs-loader-inner">
                        <label>	●</label>
                        <label>	●</label>
                        <label>	●</label>
                        <label>	●</label>
                        <label>	●</label>
                        <label>	●</label>
                    </div>
                </div>
            }
        </>
    )
}


LoadingShroud.propTypes = {
    Active: PropTypes.bool.isRequired
}

export default LoadingShroud;