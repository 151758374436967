import React, { useState, useEffect, } from 'react';
import { useParams } from 'react-router-dom';
import { GetCustomer, UpdateCustomer, ResetCustomer } from '../../../api/customer-api';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const CustomerEdit = (props) => {
    let { id } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [rates, setRates] = useState([]);
    const [customer, setCustomer] = useState(
        {
            customerID: -1,
            customerNumber: "",
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            fax: "",
            website: "",
            notificationEmail: "",
            active: true
        }
    );
    const [user, setUser] = useState(
        {
            userID: -1,
            customerID: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            changePassword: false,
            role: null,
            active: true
        }
    );

    useEffect(() => {
        loadCustomer(id)
    }, []);

    const loadCustomer = (id) => {
        GetCustomer(id)
            .then((result) => {
                if (result.success) {
                    setCustomer(result.customer);
                    setUser(result.user);
                    setRates(result.rates);
                }
                else
                    setErrorMessage(result.message);
            })
            .catch(() => {
            });
    }

    const activeChange = (e) => {
        let newCState = { ...customer };
        let newUState = { ...user };
        newCState.active = e.target.checked;
        newUState.active = e.target.checked;
        setCustomer(newCState);
        setUser(newUState);
    }
    const updateCustomerState = (e) => {
        let newState = { ...customer };
        newState[e.target.name] = e.target.value;
        setCustomer(newState);
    }
    const updateUserState = (e) => {
        let newState = { ...user };
        newState[e.target.name] = e.target.value;
        setUser(newState);
    }
    const updateRateState = (e, ridx) => {
        let newState = [...rates];
        newState[ridx][e.target.name] = Number(e.target.value);
        setRates(newState);
    }
    const copyAudioRate = (ridx) => {
        let newState = [...rates];
        let copyRate = newState[ridx];

        let confirmResult = window.confirm(`Are you sure you want to copy ${copyRate.cost} to all other Audio rate overrides?`);
        if (confirmResult) {
            newState = newState.map(item => { return { ...item, cost: copyRate.cost } });
            setRates(newState);
        }
    }
    const copyVideoRate = (ridx) => {
        let newState = [...rates];
        let copyRate = newState[ridx];

        let confirmResult = window.confirm(`Are you sure you want to copy ${copyRate.costVideo} to all other Video rate overrides?`);
        if (confirmResult) {
            newState = newState.map(item => { return { ...item, costVideo: copyRate.costVideo } });
            setRates(newState);
        }
    }

    const updateCustomer = () => {
        UpdateCustomer(customer,user,rates)
            .then((result) => {
                if (result.success) {
                    setMessage("Customer updated successfully");
                    setErrorMessage("");
                    loadCustomer(id)
                }
                else {
                    setMessage("");
                    setErrorMessage(result.message);
                }
            })
            .catch((result) => {
                setMessage("");
                setErrorMessage("Unknown error occurred.");
            });
    }
    const resetPassword = () => {
        let confirmResult = window.confirm("Are you sure you want to reset this user's password? They will recieve an automatically generated password via the login's email, and be required to change it on their first login.");
        if (confirmResult) {
            ResetCustomer(id)
                .then((result) => {
                    if (result.success) {
                        setMessage("User's password has been reset. They should recieve an email with login instructions shortly.");
                        setErrorMessage("");
                        loadCustomer(id)
                    }
                    else {
                        setMessage("");
                        setErrorMessage(result.message);
                    }
                })
                .catch(() => {
                    setMessage("");
                    setErrorMessage("Unknown error occurred.");
                });
        }
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Customer: {customer.name} </h1>
                </div>
                <div className='col-lg-auto align-middle pt-2'>
                    <Form.Check
                        className='dt-rem-3'
                        type='checkbox'
                        label='Active'
                        value={customer.active}
                        checked={customer.active}
                        name='active'
                        onChange={activeChange}
                    />
                </div>
            </div>
            <Form className='dt-rem-3  pb-5' onSubmit={(e) => { e.preventDefault(); updateCustomer() }}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value={customer.name} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridNotificationEmail">
                        <Form.Label>Customer No.</Form.Label>
                        <Form.Control type="text" placeholder="Customer ID Number" name="customerNumber" value={customer.customerNumber} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <hr />
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Address1</Form.Label>
                        <Form.Control type="text" placeholder="Address1" name="address1" value={customer.Address1} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Address2</Form.Label>
                        <Form.Control type="text" placeholder="Address2" name="address2" value={customer.Address2} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" name="city" value={customer.city} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="State" name="state" value={customer.state} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Zip" name="zip" value={customer.zip} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                      
                    </Form.Group>

                </Row>
                <hr />
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="tel" placeholder="Phone" name="phone" value={customer.phone} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control type="text" placeholder="Fax" name="fax" value={customer.fax} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    
                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="Website" name="website" value={customer.website} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridNotificationEmail">
                        <Form.Label>NotificationEmail</Form.Label>
                        <Form.Control type="email" placeholder="asd@example.com" name="notificationEmail" value={customer.notificationEmail} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <hr />
                <Row className='mb-3'>
                    <Col className='col-lg'>
                        <h3>Customer Login: {user.firstName} {user.lastName} </h3>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First name" name="firstName" value={user.firstName} onChange={(e) => updateUserState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" value={user.lastName} onChange={(e) => updateUserState(e)} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email address" name="email" value={user.email} onChange={(e) => updateUserState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="Phone number" name="phone" value={user.phone} onChange={(e) => updateUserState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Login Status</Form.Label>
                        <div className={user.changePassword === true ? 'bg-danger p-1 text-white' : 'bg-success p-1 text-white'}> {user.changePassword === true ? 'Password sent, pending user login.' : 'User has verified via email and logged in.'}</div>
                    </Form.Group>
                </Row>

                <div className='col-lg-auto align-middle pt-2'>
                    <Button className='deaftalk-button' onClick={() => { resetPassword() }}>
                        Reset Password
                    </Button>
                </div>

                <hr />
                <Row className='mb-3'>
                    <Col className='col-lg'>
                        <h3>Rates: </h3>
                    </Col>
                </Row>
                <Table striped bordered hover className='dt-table dt-rem-3'>
                    <thead>
                        <tr>
                            <th>Language Code</th>
                            <th>Audio Override</th>
                            <th>Global Audio</th>
                            <th>Video Override</th>
                            <th>Global Video</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map((r, ridx) =>
                            <tr key={r.name}>
                                <td className='col-md-3'>
                                    {r.name}
                                </td>
                                <td className='col-md-4 input-group'>
                                    <Form.Control type='number' step='.0001' placeholder='Cost' name='cost' value={r.cost} onChange={(e) => updateRateState(e, ridx)} />
                                    <span class="input-group-btn">
                                        <Button onClick={() => copyAudioRate(ridx) }> <FontAwesomeIcon icon={faCopy} /> </Button>
                                    </span>
                                </td>
                                <td className='col-md-1'>
                                    {r.globalCost}
                                </td>
                                <td className='col-md-2 input-group'>
                                    <Form.Control type="number" step=".0001" placeholder="Cost" name="costVideo" value={r.costVideo} onChange={(e) => updateRateState(e, ridx)} />
                                    <span class="input-group-btn">
                                        <Button onClick={() => copyVideoRate(ridx)}> <FontAwesomeIcon icon={faCopy} /> </Button>
                                    </span>
                                </td>
                                <td className='col-md-1'>
                                    {r.globalCostVideo}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {message.length > 0 ?
                    <Alert variant="success">
                        {message}
                    </Alert>
                    : null
                }
                {errorMessage.length > 0 ?
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                    : null
                }
                <div className='pt-2 row'>
                    <div className='col-lg'>
                        <Button className='deaftalk-button' type="submit">
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default CustomerEdit;
