import React, { useState, useEffect, } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import { GetRates, AddRate, DeleteRate } from '../../../api/rates-api';

const RateList = (props) => {
    const [rates, setRates] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [rate, setRate] = useState({ name: "", cost: 0.00, costVideo: 0.00 });

    useEffect(() => {
        loadRates()
    }, []);

    const loadRates = () => {
        setLoading(true);
        GetRates()
            .then((result) => {
                if (result.success){
                    setRates(result.rates);
                    setLoading(false);
                }
            })
            .catch (() => {
                setLoading(false);
            });
    }
    const deleteRate = (rateID, rateName) => {
        let confirmResult = window.confirm("Are you sure you want to delete the rate code: " + rateName);
        if (confirmResult) {
            DeleteRate(rateID)
                .then((result) => {
                    if (result.success)
                        loadRates()
                })
                .catch(() => {
                });
        }
    }
    const addRate = () => {
        if (rate.name.length <= 0) {
            setMessage("Please provide a valid rate code.");
            return;
        }
        if (rate.cost <= 0) {
            setMessage("Please provide a valid audio cost.");
            return;
        }
        if (rate.cost <= 0) {
            setMessage("Please provide a valid video cost.");
            return;
        }
        AddRate(rate).then((result) => {
            if (result.success) {
                setMessage("");
                setRate({ name: "", cost: 0.00, costVideo: 0.00 });
                loadRates()
            } else {
                setMessage(result.message);
            }
        })
            .catch(() => {
            });
    }
    const updateRate = (e) => {
        let newState = { ...rate };
        newState[e.target.name] = e.target.value;
        setRate(newState);
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Global Rates</h1>
                </div>

            </div>
            {loading && rates.length == 0 ?
                <div className='pt-2 row'>
                    <Spinner animation="border" role="status" className="dt-spinner-border-sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
                <Table striped bordered hover className='dt-table dt-rem-3'>
                    <thead>
                        <tr>
                            <th>Language Code</th>
                            <th>Audio Cost</th>
                            <th>Video Cost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map((r) =>
                            <tr key={r.name}>
                                <td className='col-md-7'>{r.name}</td>
                                <td className='col-md-2'>{r.cost}</td>
                                <td className='col-md-2'>{r.costVideo}</td>
                                <td className='col-md-1 text-center'>
                                    <Button className='deaftalk-button w-100' onClick={() => deleteRate(r.rateID, r.name)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className='col-md-7'>
                                <Form.Control type="text" placeholder="Code" name="name" value={rate.name} onChange={(e) => updateRate(e)} />
                            </td>
                            <td className='col-md-2'>
                                <Form.Control type="number" step=".0001" placeholder="Audio" name="cost" value={rate.cost} onChange={(e) => updateRate(e)} />
                            </td>
                            <td className='col-md-2'>
                                <Form.Control type="number" step=".0001" placeholder="Video" name="costVideo" value={rate.costVideo} onChange={(e) => updateRate(e)} />
                            </td>
                            <td className='col-md-1'>
                                <Button className='deaftalk-button w-100' onClick={() => addRate()}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            }
            {message.length > 0 ?
                <Alert variant="danger">
                    {message}
                </Alert>
                : null
            }
        </div>
    );
};
export default RateList;
