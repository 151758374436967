import React, { useState, useEffect } from 'react';
import LoadingShroud from '../loading-shroud';
import { useNavigate } from 'react-router-dom';
import { UpdatePassword } from '../../api/auth-api';

const SetPassword = props => {
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "DT Interpreting - Update Password"
    }, []);
    
    const updatePassword = () => {
        if (newPassword1 != newPassword2) {
            setMessage("Passwords do not match.")
            return;
        }
        if (newPassword1.length < 8 || newPassword1.length > 16) {
            setMessage("Passwords must be between 8 and 16 characters.")
            return;
        }
        UpdatePassword(newPassword2).then(result => {
            if (result.success) {
                setMessage("")
                props.needsChangePassword(false);
            } else {
                setMessage(result.message);
            }
        }).catch(info => {
            console.error(info);
        })
    }
    return (
        <div className="form-login">
            <div className="app-container-login">
                <div className="container">
                    <div className="row justify-content-center h-100">
                        <div className="col-xl-6 col-sm-12 m-auto white-bg p1-border px-5 py-3 rounded">
                            <div className="row justify-content-center">
                                <div className="col-10 row justify-content-center">
                                    <img className="img-fluid" src="../images/dt-logo-sq.png" alt="Deaftalk Portal" style={{ "width": "340px", "height": "340px" }} />
                                </div>
                            </div>
                            <form className="" method="post" onSubmit={(e) => { e.preventDefault(); updatePassword() }}>
                                <div className="row justify-content-center">
                                    <div className="col-10 row justify-content-center">
                                        You must change your password. Please enter in a new password between 8 and 16 characters.
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col text-center">
                                        <input type="password" className="deaftalk-input-100" name="Password1" id="Password1" placeholder="New Password" value={newPassword1}
                                            onChange={e => setNewPassword1(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col text-center">
                                        <input type="password" className="deaftalk-input-100" name="Password2" id="Password2" placeholder="New Password" value={newPassword2}
                                            onChange={e => setNewPassword2(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <span style={{ color: 'red' }}>{message}</span>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col text-right">
                                        <input type="submit" value="Update Password" className="deaftalk-button deaftalk-login-button" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetPassword;
