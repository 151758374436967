import APIRequest from './api';

export const GetInvoiceAll = (customerID, startDate, endDate) => {
    return APIRequest({
        url: `Export/InvoiceAll`,
        respType: "raw",
        method: "post",
        body: {
            customerID: customerID,
            startDate: startDate,
            endDate: endDate
        }
    });
}

export const GetInvoice = (customerID, startDate, endDate) => {
    //params.respType
    return APIRequest({
        url: `Export/Invoice`,
        respType: "raw",
        method: "post",
        body: {
            customerID: customerID,
            startDate: startDate,
            endDate: endDate
        }
    });
}
