import APIRequest from './api';

export const GetRates = () => {
    return APIRequest({
        url: `Rates/GetRates`,
        method: "get"
    });
}

export const AddRate = (rate) => {
    return APIRequest({
        url: `Rates/AddRate`,
        method: "post",
        body: {
            rate: rate
        }
    });
}

export const DeleteRate = (rateID) => {
    return APIRequest({
        url: `Rates/DeleteRate`,
        method: "post",
        body: {
            rateID: rateID
        }
    });
}