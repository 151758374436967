import React, { useState, useEffect, } from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';

import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { GetCustomers } from '../../../api/customer-api';
import { UploadFilePond, ImportStagedCallData } from '../../../api/import-api';

const Import = (props) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const [showFilePond, setShowFilePond] = useState(false);
    const [fileUploadComplete, setFileUploadComplete] = useState(false);
    const [importComplete, setImportComplete] = useState(false);

    const [importHints, setImportHints] = useState([
        { importField: "SessionID", bs: "Session ID", cli: "Session ID", description: "Unique call identifier from datasheets. If one is not provided, one will be automatically generated." },
        { importField: "Customer", bs: "Custtomer Name", cli: "Customer_Name", description: "Customer name. This field is only temporarily used to display the data on stage 2 of the import. Is discarded after the records are mapped into our customer list." },
        { importField: "*Cust No.", bs: "Customer ID", cli: "Cust_No", description: "Customer identification number." },
        { importField: "*Language", bs: "Expertise", cli: "Language", description: "Language identifier used to attribute rates to the call." },
        { importField: "*Call Type", bs: "Call Type", cli: "", description: "Call type (AUDIO/VIDEO) which determines which rate we use for the language. Note that CLI does not have an equivalent field. Their sheet does not include this as a column, as such the data sheet will need formatted to include a Call Type field." },
        { importField: "Wait Sec", bs: "Wait Time", cli: "Hold Time Seconds", description: "Seconds the caller was waiting on the line for an available agent." },
        { importField: "*Bill Mins", bs: "Duration Minutes", cli: "Total", description: "Total duration of the call in minutes calculated against the corresponding rate for this language and call type." },
        { importField: "*Date", bs: "Start Time", cli: "", description: "Bluestream sheet shows date and time in the same field, so only a single field is necessary." },
        { importField: "*Date", bs: "", cli: "Date", description: "CLI sheet shows date formatted separately from time of day. A composite of both fields is used to make the date post-import." },
        { importField: "*Date", bs: "", cli: "Start", description: "CLI sheet shows time of day formatted separately from the date. A composite of both fields is used to make the date post-import." }
    ]);

    const [calls, setCalls] = useState([]);
    const [problemCalls, setProblemCalls] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const [errors, setErrors] = useState([]);

    var pond = React.createRef();

    const confirmCustomer = () => {
        setShowFilePond(true);
    }

    const reset = () => {
        setShowFilePond(false);
        setFileUploadComplete(false);
        setImportComplete(false);
        setCalls([]);
        setProblemCalls([]);
        setWarnings([]);
        setErrors([]);
        setErrorMessage("");
        setMessage("");
    }

    const setServer = () => {
        const formData = [{ }]; // Include payload if needed.
        return UploadFilePond({
            controller: 'Import',
            action: 'CallData',
            method: 'POST',
            formData: formData,
            onload: (response, pond) => {
                var data = JSON.parse(response);
                setFileUploadComplete(true);
                setCalls(data.calls);
                setProblemCalls(data.problemCalls);
                setWarnings(data.warnings);
                setErrors(data.errors);
                if (!data.success) {
                    setErrorMessage(data.message);
                    setMessage("");
                } else {
                    setMessage(data.message);
                    setErrorMessage("");
                }
            },
        });
    }
    const completeImport = () => {
        let confirmResult = window.confirm("Are you sure you want to import all of these records?");
        if (confirmResult) {
            setShowFilePond(false);
            setFileUploadComplete(false);
            setImportComplete(false);
            ImportStagedCallData(calls)
                .then((result) => {
                    if (result.success) {
                        reset();
                        setMessage(result.message);
                    } else {
                        reset();
                        setErrorMessage(result.message);
                    }
                })
                .catch(() => {
                    reset();
                    setErrorMessage("Unknown error.");
            });
        }
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Import Data</h1>
                </div>
                <Row className="pt-2 row">
                    Data formats based on Bluestream & CLI data sheets in CSV format. Import field is our internal fieldname for the call data. BS and CLI fields are noted here as the expected column name to search for in the sheet. For required fields, one or the other must be present on the sheet. See the warnings or errors after uploading for hints on missing data or unmapped fields on the sheet.
                    <br />
                    <br />
                    Validation warnings and errors will be shown along call details that failed and passed validation after a datasheet has been uploaded. Be sure to check the data at this stage, as approving the data for import is a one time process. If records need to be removed after, please contact the site administrator.
                    <Table striped bordered hover className='dt-table dt-rem-1'>
                        <thead>
                            <tr>
                                <th className="col-lg-2">ImportField</th>
                                <th className="col-lg-2">BS</th>
                                <th className="col-lg-2">CLI</th>
                                <th className="col-lg-6">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {importHints.map((c, index) =>
                                <tr key={index}>
                                    <td className='col-md-2'>{c.importField}</td>
                                    <td className='col-md-2'>{c.bs}</td>
                                    <td className='col-md-2'>{c.cli}</td>
                                    <td className='col-md-2'>{c.description}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    (Fields marked with * are required to pass import validation)
                </Row>
            </div>
            <Form className='dt-rem-3' onSubmit={(e) => { e.preventDefault(); confirmCustomer(); }}>
                <Row className="pt-2 row">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Button className="deaftalk-button" type="submit"> Confirm & Start </Button>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridFName" className="text-end">
                        <Button className="deaftalk-button" onClick={() => reset()} > Reset </Button>
                    </Form.Group>
                </Row>
                <hr />
                <Row className="pt-2 row">
                    {showFilePond && (
                        <div className="telephony-filepond-container">
                            <FilePond
                                ref={pond}
                                allowMultiple={false}
                                server={setServer()}
                                disabled={fileUploadComplete}
                            />
                            <hr />
                        </div>
                    )}
                </Row>
                {message.length > 0 ?
                    <Alert variant="success">
                        {message}
                    </Alert>
                    : null
                }
                {errorMessage.length > 0 ?
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                    : null
                }
                {(!importComplete && fileUploadComplete) &&
                    <>

                        <Button className='deaftalk-button w-100 p-3' onClick={() => completeImport()}>
                            Complete Import <FontAwesomeIcon icon={faCheck} />
                        </Button>
                        <Accordion defaultActiveKey="3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Warnings ({warnings.length})</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="pt-2 row">
                                        <Row className="pt-2 row">
                                            <Col className='col-lg'>
                                                {warnings.map((w, widx) =>
                                                    <Alert key={widx} variant="warning">
                                                        {w}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                    </Row>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Errors ({errors.length})</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="pt-2 row">
                                        <Row className="pt-2 row">
                                            <Col className='col-lg'>
                                                {errors.map((e, eidx) =>
                                                    <Alert key={eidx} variant="danger">
                                                        {e}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <hr />
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Failed Calls ({problemCalls.length}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="pt-2 row">
                                        <Table striped bordered hover className='dt-table dt-rem-3'>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Customer</th>
                                                    <th>Cust No.</th>
                                                    <th>Language</th>
                                                    <th>CallType</th>
                                                    <th>Wait Sec</th>
                                                    <th>Bill Mins</th>
                                                    <th>Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {problemCalls.map((c) =>
                                                    <tr key={c.callID}>
                                                        <td className='col-md-2'>{c.dateDisplay}</td>
                                                        <td className='col-md-2'>{c.customerName}</td>
                                                        <td className='col-md-2'>{c.customerNumber}</td>
                                                        <td className='col-md-2'>{c.languageCode}</td>
                                                        <td className='col-md-2'>{c.callType}</td>
                                                        <td className='col-md-1'>{c.waitsec}</td>
                                                        <td className='col-md-1'>{c.billmins}</td>
                                                        <td className='col-md-1'>{c.rate}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Staged Calls ({calls.length})</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="pt-2 row">
                                        <Table striped bordered hover className='dt-table dt-rem-3'>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Customer</th>
                                                    <th>Cust No.</th>
                                                    <th>Language</th>
                                                    <th>CallType</th>
                                                    <th>Wait Sec</th>
                                                    <th>Bill Mins</th>
                                                    <th>Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {calls.map((c) =>
                                                    <tr key={c.callID}>
                                                        <td className='col-md-2'>{c.dateDisplay}</td>
                                                        <td className='col-md-2'>{c.customerName}</td>
                                                        <td className='col-md-2'>{c.customerNumber}</td>
                                                        <td className='col-md-2'>{c.languageCode}</td>
                                                        <td className='col-md-2'>{c.callType}</td>
                                                        <td className='col-md-1'>{c.waitsec}</td>
                                                        <td className='col-md-1'>{c.billmins}</td>
                                                        <td className='col-md-1'>{c.rate}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>}
            </Form>
        </div>
    );
};
export default Import;
