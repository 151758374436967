import React, { useState, useEffect, useRef } from 'react';
import DateTimePicker from 'react-date-picker';
import { GetNotes, AddNote } from '../../api/notes-api';

const NoteBoard = (props) => {
    const placeholderDiv = useRef(null);

    const [notes, setNotes] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [noteDate, setNoteDate] = useState(new Date());
    const [shouldScroll, setShouldScroll] = useState(false);
    const dateRef = useRef(noteDate);
    const noteRef = useRef(notes);

    const [noteText, setNoteText] = useState("");


    const [timer, setTimer] = useState(-1);
    const timerRef = useRef(timer);

    noteRef.current = notes;
    dateRef.current = noteDate;
    timerRef.current = timer;

    const TIMER_INTERVAL = 3000;


    useEffect(() => {
        const id = setTimeout(() => { loadNotes(); }, 1000)
        setTimer(id);
        return () => {
            if (timerRef.current !== -1)
                clearTimeout(timerRef.current)
            else
                clearTimeout(id)
        };
    }, []);

    useEffect(() => {
        if (isToday(noteDate))
            if (shouldScroll)
                scrollToBottom();
            
    }, [notes]);

    const loadNotes = (date) => {
        clearTimeout(timerRef.current);
        let queryDate = dateRef.current;
        if (date) {
            queryDate = date;
        }
        GetNotes(queryDate)
            .then((result) => {
                if (result.success) {
                    setShouldScroll(noteRef.current.length !== result.notes.length);
                    setNotes(result.notes);
                    noteRef.current = result.notes;
                }
                setTimer(setTimeout(() => { loadNotes(); }, TIMER_INTERVAL));
                setErrorMessage("")
            })
            .catch(() => {
                setErrorMessage("Unable to load notes, trying again shortly.")
                setTimer(setTimeout(() => { loadNotes(); }, 10000));
            });
    }
    const addNote = () => {
        AddNote(noteText)
            .then((result) => {
                if (result.success) {
                    setNoteText("")
                    loadNotes();
                }
                setErrorMessage("")
            })
            .catch(() => {
                //setErrorMessage("Unable to send note.")
            });
    }
    const isToday = (date) => {
        const today = new Date()
        return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
    }

    const scrollToBottom = () => {
        placeholderDiv.current?.scrollIntoView({ behavior: 'smooth' })
        setShouldScroll(false);
    }


    return (
        <div>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Notes</h1>
                </div>
                <div className='col-lg-auto align-middle pt-2'>
                    <DateTimePicker value={noteDate} onChange={date => { setNoteDate(date); loadNotes(date); } } clearIcon={null} />
                </div>
            </div>
            <div className='pt-2 row notes-body'>
                {errorMessage.length > 0 ?
                    errorMessage
                    :
                    notes.map(n =>
                        <div className={`note-card ${n.owner ? 'text-end owner' : 'other'}`} key={n.noteID}>
                            <div className='note-date'>{n.dateText}</div>
                            <div className='note-name'>{n.name}:</div>
                            <div className='note-text'>{n.text}</div>
                        </div>
                    )
                }
                <div style={{ float: "left", clear: "both" }} ref={placeholderDiv}> </div>
            </div>
            <form className="p-5 row " method="post" onSubmit={(e) => { e.preventDefault(); addNote() }}>
                <div className="col-lg"><input className="deaftalk-input-100" type='text' value={noteText} onChange={e => setNoteText(e.target.value)} /></div>
                <div className="col-lg-auto align-middle p-1"><input className="deaftalk-button" type="submit" /></div>
            </form>
        </div>
    );
};
export default NoteBoard;
