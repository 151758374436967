const Header = (props) => {
    return (
        <div className="container-fluid no-print app-header">
            <div className="row bg-white p-3">
                <div className="col-lg-3 col-md-6 col-sm-12 my-auto">
                    <img className="img-fluid header-image" src="../images/dt-logo-rect.png" alt="DT Interpreting" /></div>
                <div className="col-6 d-none d-xl-block d-lg-block"></div>
                <div className="col-lg-3 col-md-6 col-sm-12"></div>
            </div>
        </div >
    )
}

export default Header;
