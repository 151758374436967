import React, { useState, useEffect } from 'react';
import LoadingShroud from '../loading-shroud';
import { useNavigate } from 'react-router-dom';

import { Authenticate } from '../../api/auth-api';

const LoginStaff = props => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "DT Interpreting - Login"
    }, []);

    const login = () => {
        setLoading(true);
        Authenticate(username, password).then(result => {
            if (result.success && result.token != null) {
                localStorage.setItem("deaftalk-token", result.token);
                sessionStorage.setItem("deaftalk-token", result.token);

                setLoading(false);
                props.checkAuth();

                navigate(`/Dashboard`)
            } else {
                var message = "Invalid username or password."
                setMessage(message);
                setLoading(false);
            }
        }).catch(info => {
            console.error(info);
        })
    }
    return (
        <>
            {loading ? (
                <div className="app-loading">
                    <LoadingShroud Active={loading} />
                </div>
            ) : (
                <>
                    <div className="row justify-content-center">
                        <div className="col-10 row justify-content-center">
                            <img className="img-fluid" src="../images/dt-logo-sq.png" alt="Deaftalk Portal" style={{ "width": "340px", "height": "340px" }} />
                        </div>
                    </div>
                    <form className="" method="post" onSubmit={(e) => { e.preventDefault(); login() }}>
                        <div className="row mt-3">
                            <div className="col text-center">
                                <input type="text" className="deaftalk-input-100" name="Username" id="Username" placeholder="Username" value={username}
                                    onChange={e => setUsername(e.target.value)} required />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col text-center">
                                <input type="password" className="deaftalk-input-100" name="Password" id="Password" placeholder="Password" value={password}
                                    onChange={e => setPassword(e.target.value)} required />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <span style={{ color: 'red' }}>{message}</span>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col text-right">
                                <input type="submit" value="Login" className="deaftalk-button deaftalk-login-button" />
                            </div>
                        </div>
                    </form>
                </>
            )}
        </>
    )
}

export default LoginStaff;
