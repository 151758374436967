import { React, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { GetNavigation } from '../api/navigation-api';
import Header from './layout/header';
import Navigation from './layout/navigation';
import Dashboard from './dashboard/dashboard';
import CustomerList from './admin/customer/customer-list';
import CustomerEdit from './admin/customer/customer-edit';
import CustomerCreate from './admin/customer/customer-create';
import EmployeeList from './admin/employee/employee-list';
import EmployeeEdit from './admin/employee/employee-edit';
import EmployeeCreate from './admin/employee/employee-create';
import Import from './admin/import/import';
import RateList from './admin/rates/rate-list';
import Reports from './reports/reports';

const MainRouter = (props) => {
    let location = useLocation()
    let navigate = useNavigate();

   


    return (
        <div className="pb-5">
            <Header />
            <Navigation logout={props.logout}/>
            <Routes>
                <Route
                    exact
                    path="/Dashboard"
                    element={<Dashboard role={props.role} userType={props.userType}/>}
                />
                <Route
                    exact
                    path="/Admin/Employees/New"
                    element={<EmployeeCreate />}
                />
                <Route
                    exact
                    path="/Admin/Employees/:id"
                    element={<EmployeeEdit />}
                />
                <Route
                    path="/Admin/Employees"
                    element={<EmployeeList />}
                />
                <Route
                    exact
                    path="/Admin/Rates"
                    element={<RateList />}
                />
                <Route
                    exact
                    path="/Admin/Customers/New"
                    element={<CustomerCreate />}
                />
                <Route
                    exact
                    path="/Admin/Customers/:id"
                    element={<CustomerEdit />}
                />
                <Route
                    path="/Admin/Customers"
                    element={<CustomerList />}
                />
                <Route
                    exact
                    path="/Admin/Import"
                    element={<Import />}
                />
                <Route
                    exact
                    path="/Reports"
                    element={<Reports role={props.role} userType={props.userType} />}
                />
            </Routes>
        </div>
    );
};
export default MainRouter;
