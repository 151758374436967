import React, { useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateCustomer } from '../../../api/customer-api';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const CustomerCreate = (props) => {
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [rates, setRates] = useState([]);
    const [customer, setCustomer] = useState(
        {
            customerID: -1,
            customerNumber: "",
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            fax: "",
            website: "",
            notificationEmail: "",
            active: true
        }
    );
    const [user, setUser] = useState(
        {
            userID: -1,
            customerID: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            changePassword: true,
            role: null,
            active: true
        }
    );
    const updateCustomerState = (e) => {
        let newState = { ...customer };
        newState[e.target.name] = e.target.value;
        setCustomer(newState);
    }
    const updateUserState = (e) => {
        let newState = { ...user };
        newState[e.target.name] = e.target.value;
        setUser(newState);
    }
    const createCustomer = () => {
        CreateCustomer(customer, user, rates)
            .then((result) => {
                if (result.success) {
                    navigate(`/Admin/Customers`)
                }
                else {
                    setMessage("");
                    setErrorMessage(result.message);
                }
            })
            .catch((result) => {
                setMessage("");
                setErrorMessage("Unknown error occurred.");
            });
    }
    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Create New Customer</h1>
                </div>
            </div>
            <Form className='dt-rem-3  pb-5' onSubmit={(e) => { e.preventDefault(); createCustomer() }}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" name="name" value={customer.name} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridNotificationEmail">
                        <Form.Label>Customer No.</Form.Label>
                        <Form.Control type="text" placeholder="Customer ID Number" name="customerNumber" value={customer.customerNumber} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <hr />
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Address1</Form.Label>
                        <Form.Control type="text" placeholder="Address1" name="address1" value={customer.Address1} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Address2</Form.Label>
                        <Form.Control type="text" placeholder="Address2" name="address2" value={customer.Address2} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" name="city" value={customer.city} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="State" name="state" value={customer.state} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Zip" name="zip" value={customer.zip} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">

                    </Form.Group>

                </Row>
                <hr />
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="tel" placeholder="Phone" name="phone" value={customer.phone} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control type="text" placeholder="Fax" name="fax" value={customer.fax} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="Website" name="website" value={customer.website} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridNotificationEmail">
                        <Form.Label>NotificationEmail</Form.Label>
                        <Form.Control type="email" placeholder="asd@example.com" name="notificationEmail" value={customer.notificationEmail} onChange={(e) => updateCustomerState(e)} />
                    </Form.Group>
                </Row>
                <hr />
                <Row className='mb-3'>
                    <Col className='col-lg'>
                        <h3>Customer Login: {user.firstName} {user.lastName} </h3>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First name" name="firstName" value={user.firstName} onChange={(e) => updateUserState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" value={user.lastName} onChange={(e) => updateUserState(e)} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email address" name="email" value={user.email} onChange={(e) => updateUserState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="Phone number" name="phone" value={user.phone} onChange={(e) => updateUserState(e)} />
                    </Form.Group>
                </Row>

                {message.length > 0 ?
                    <Alert variant="success">
                        {message}
                    </Alert>
                    : null
                }
                {errorMessage.length > 0 ?
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                    : null
                }
                <div className='pt-2 row'>
                    <div className='col-lg'>
                        <Button className='deaftalk-button' type="submit">
                            Add Customer
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default CustomerCreate;
