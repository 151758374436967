import React, { useState, useEffect, } from 'react';
import { useParams } from 'react-router-dom';
import { GetEmployee, UpdateEmployee, ResetEmployee } from '../../../api/employee-api';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const EmployeeEdit = (props) => {
    let { id } = useParams(); 
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [employee, setEmployee] = useState(
        {
            userID: -1,
            customerID: null,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            changePassword: null,
            role: 1,
            active: true
        }
    );
    const [roles, setRoles] = useState([
        { name: "Admin", value: 0 },
        { name: "Agent", value: 1 }
    ]);


    useEffect(() => {
        loadEmployee(id)
    }, []);

    const activeChange = (e) => {
        let newState = { ...employee };
        newState.active = e.target.checked;
        setEmployee(newState);
    }
    const updateEmployeeState = (e) => {
        let newState = { ...employee };
        newState[e.target.name] = e.target.value;
        setEmployee(newState);
    }

    const loadEmployee = (id) => {
        GetEmployee(id)
            .then((result) => {
                if (result.success)
                    setEmployee(result.employee);
                else
                    setErrorMessage(result.message);
            })
            .catch(() => {
            });
    }

    const updateEmployee = () => {
        let payload = employee;
        payload.role = Number(payload.role);
        UpdateEmployee(payload)
            .then((result) => {
                if (result.success) {
                    setMessage("User updated successfully");
                    setErrorMessage("");
                }
                else {
                    setMessage("");
                    setErrorMessage(result.message);
                }
            })
            .catch((result) => {
                setMessage("");
                setErrorMessage("Unknown error occurred.");
            });
    }

    const resetPassword = () => {
        let confirmResult = window.confirm("Are you sure you want to reset this user's password? They will recieve an automatically generated password, and be required to change it on their first login.");
        if (confirmResult) {
            ResetEmployee(id)
                .then((result) => {
                    if (result.success) {
                        setMessage("User's password has been reset. They should recieve an email with login instructions shortly.");
                        setErrorMessage("");
                        loadEmployee(id)
                    }
                    else {
                        setMessage("");
                        setErrorMessage(result.message);
                    }
                })
                .catch(() => {
                    setMessage("");
                    setErrorMessage("Unknown error occurred.");
                });
        } 
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>User: {employee.firstName} {employee.lastName}</h1>
                </div>
                <div className='col-lg-auto align-middle pt-2'>
                    <Form.Check
                        className='dt-rem-3'
                        type='checkbox'
                        label='Active'
                        value={employee.active}
                        checked={employee.active}
                        name='active'
                        onChange={activeChange}
                    />
                </div>
            </div>
            <Form className='dt-rem-3' onSubmit={(e) => { e.preventDefault(); updateEmployee() }}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridFName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First name" name="firstName" value={employee.firstName} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last name" name="lastName" value={employee.lastName} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email address" name="email" value={employee.email} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="Phone number" name="phone" value={employee.phone} onChange={(e) => updateEmployeeState(e)} />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Role</Form.Label>
                        <Form.Select name="role" value={employee.role} onChange={(e) => updateEmployeeState(e)} >
                            {roles.map((r) =>
                                <option key={r.value} value={r.value} >{r.name}</option> 
                            )}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                        <Form.Label>Login Status</Form.Label>
                        <div className={employee.changePassword === true ? 'bg-danger p-1 text-white' : 'bg-success p-1 text-white'}> {employee.changePassword === true ? 'Password sent, pending user login.' : 'User has verified via email and logged in.'}</div>
                    </Form.Group>
                </Row>
                {message.length > 0 ?
                    <Alert variant="success">
                        {message}
                    </Alert>
                    : null
                }
                {errorMessage.length > 0 ? 
                    <Alert variant="danger">
                        { errorMessage }
                    </Alert>
                    : null
                }
                <div className='pt-2 row'>
                    <div className='col-lg'>
                        <Button className='deaftalk-button' type="submit">
                            Save Changes
                        </Button>
                    </div>
                    <div className='col-lg-auto align-middle pt-2'>
                        <Button className='deaftalk-button' onClick={() => { resetPassword()} }>
                            Reset Password
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default EmployeeEdit;
