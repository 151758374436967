import React, { useState, useEffect, } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { GetCustomers } from '../../../api/customer-api';
import { Link, useNavigate } from "react-router-dom";

const CustomerList = (props) => {
    const [customers, setCustomers] = useState([]);
    const [displayCustomers, setDisplayCustomers] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        loadCustomers(active)
    }, [active]);

    useEffect(() => {
        filterCustomers(filter);
    }, [customers]);

    const loadCustomers = (active) => {
        setLoading(true);
        GetCustomers(active)
            .then((result) => {
                if (result.success) {
                    setCustomers(result.customers);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const filterCustomers = (filter) => {
        setFilter(filter);
        if (filter.length > 0) {
            var filtered = customers.filter(function (c) {
                return (c.customerNumber.indexOf(filter.trim()) >= 0 || c.name.toUpperCase().indexOf(filter.trim().toUpperCase()) >= 0 || c.notificationEmail.toUpperCase().indexOf(filter.trim().toUpperCase()) >= 0 || c.website.toUpperCase().indexOf(filter.trim().toUpperCase()) >= 0);
            });
            setDisplayCustomers(filtered);
        } else {
            setDisplayCustomers(customers);
        }
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Customers</h1>
                </div>
                <div className='col-lg-auto align-middle pt-2'>
                    <Form.Check
                        className='dt-rem-3'
                        type='checkbox'
                        label='Active'
                        checked={active}
                        onChange={() => { setActive(!active) }}
                    />
                </div>
            </div>
            <div className='pt-2 row dt-rem-3'>
                <div className='col-lg'>
                    <button className="deaftalk-button" onClick={() => navigate(`/Admin/Customers/New`)}> Add New Customer </button>
                </div>
            </div>
            {loading ?
                <div className='pt-2 row'>
                    <Spinner animation="border" role="status" className="dt-spinner-border-sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
                <>
                    <Form className='dt-rem-3' onSubmit={(e) => { e.preventDefault(); filterCustomers() }}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridFName">
                                <Form.Label>Keyword Filter</Form.Label>
                                <Form.Control type="text" placeholder="Filter" name="filter" value={filter} onChange={(e) => filterCustomers(e.target.value)} />
                            </Form.Group>
                        </Row>
                    </Form>
                    <Table striped bordered hover className='dt-table dt-rem-3'>
                        <thead>
                            <tr>
                                <th>Cust No.</th>
                                <th>Customer</th>
                                <th>Notification Email</th>
                                <th>Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayCustomers.map((c) =>
                                <tr key={c.customerID}>
                                    <td><Link to={`/Admin/Customers/${c.customerID}`}>{c.customerNumber}</Link></td>
                                    <td><Link to={`/Admin/Customers/${c.customerID}`}>{c.name}</Link></td>
                                    <td>{c.notificationEmail}</td>
                                    <td><a href={c.website}>{c.website}</a></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    );
};
export default CustomerList;
