import React, { useState, useEffect, } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { GetEmployees } from '../../../api/employee-api';
import { Link, useNavigate } from "react-router-dom";

const EmployeeList = (props) => {
    const [employees, setEmployees] = useState([]);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadEmployees(active)
    }, [active]);

    const loadEmployees = (active) => {
        setLoading(true);
        GetEmployees(active)
            .then((result) => {
                if (result.success) {
                    setEmployees(result.employees);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <div className='container'>
            <div className='pt-2 row'>
                <div className='col-lg'>
                    <h1>Employees</h1>
                </div>
                <div className='col-lg-auto align-middle pt-2'>
                    <Form.Check
                        className='dt-rem-3'
                        type='checkbox'
                        label='Active'
                        checked={active}
                        onChange={() => { setActive(!active) }}
                    />
                </div>
            </div>
            <div className='pt-2 row dt-rem-3'>
                <div className='col-lg'>
                    <button className="deaftalk-button" onClick={() => navigate(`/Admin/Employees/New`)}> Add New Employee </button>
                </div>
            </div>
            {loading ?
                <div className='pt-2 row'>
                    <Spinner animation="border" role="status" className="dt-spinner-border-sm">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                :
                <Table striped bordered hover className='dt-table dt-rem-3'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((e) =>
                            <tr key={e.userID}>
                                <td><Link to={`/Admin/Employees/${e.userID}`}>{e.firstName} {e.lastName}</Link></td>
                                <td>{e.email}</td>
                                <td>{e.phone}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            }
        </div>
    );
};
export default EmployeeList;
